import React, { useContext, createContext, useState } from 'react';
import PropTypes from 'prop-types';
import userValidationSchema from 'utils/userValidationSchema';
import useSession from 'features/auth/hooks/useSession';
import useLogin from 'features/auth/hooks/useLogin';
import useRegister from 'features/auth/hooks/useRegister';
import useLogout from 'features/auth/hooks/useLogout';
import FullPageSpinner from 'components/FullPageSpinner';
import FullPageError from 'components/FullPageError';

const AuthContext = createContext();
const { Provider } = AuthContext;

const AuthProvider = ({ children }) => {
  const [authState, setAuthState] = useState({
    user: null,
    error: null,
  });
  const [waitingForSessionData, setWaitingForSessionData] = useState(true);

  const { error, isError } = useSession(
    setAuthState,
    setWaitingForSessionData,
    {
      enabled: waitingForSessionData,
    },
  );

  const isAuthenticated = userValidationSchema.isValidSync(authState.user);
  const login = useLogin(setAuthState);
  const register = useRegister();
  const logout = useLogout(setAuthState);

  if (waitingForSessionData) return <FullPageSpinner />;

  if (isError) return <FullPageError error={error} />;

  return (
    <Provider
      value={{
        authState,
        setAuthState,
        isAuthenticated,
        login,
        register,
        logout,
      }}
    >
      {children}
    </Provider>
  );
};

const useAuth = () => useContext(AuthContext);

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export { useAuth, AuthProvider };
