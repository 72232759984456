import { publicAxios } from 'utils/axios';
import { useMutation } from 'react-query';
import { useNotificationStore } from 'contexts/NotificationStoreContext';
import getNotificationMessage from 'features/notification-store/utils/getNotificationMessage';
import { useNavigate } from 'react-router-dom';

const registerUser = async (credentials) => {
  const { data } = await publicAxios.post('/register', credentials);

  return data;
};

const useRegister = (options) => {
  const { addNotification } = useNotificationStore();
  const navigate = useNavigate();

  return useMutation(registerUser, {
    onSuccess: ({ message }) => {
      addNotification({
        message: getNotificationMessage({ message }),
      });
      navigate('/', { replace: true });
    },
    onError: ({ response: { data: error } }) => {
      addNotification({
        isError: true,
        message: getNotificationMessage({ error }),
      });
    },
    ...options,
  });
};

export default useRegister;
