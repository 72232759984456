import React from 'react';
import { Modal, Spinner } from 'react-bootstrap';

const FullPageSpinner = () => (
  <Modal
    show
    centered
    animation={false}
    fullscreen
    backdropClassName="spinner-backdrop"
  >
    <Spinner
      className="loading-spinner m-auto"
      animation="border"
      role="status"
      variant="primary"
    >
      <span className="visually-hidden">Chargement...</span>
    </Spinner>
  </Modal>
);

export default FullPageSpinner;
