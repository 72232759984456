import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'react-bootstrap';
import getNotificationMessage from 'features/notification-store/utils/getNotificationMessage';

const FullPageError = ({ error }) => (
  <Modal show centered animation={false} fullscreen>
    <div className="text-center text-danger mt-3">
      <h2>Une erreur est survenue!</h2>
      <h5>{getNotificationMessage({ error: error.response.data })}</h5>
      <p className="text-secondary">
        Nous vous conseillons de rafraichir la page.
        <br /> Si l&apos;erreur persiste, patientez un moment avant de
        rafraichir à nouveau.
      </p>
    </div>
  </Modal>
);

FullPageError.propTypes = {
  error: PropTypes.shape().isRequired,
};

export default FullPageError;
