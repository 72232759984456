import React from 'react';

const Footer = () => {
  const year = new Date().getFullYear();

  return (
    <div className="text-center text-secondary" style={{ marginTop: '3rem' }}>
      &copy; SNC {year}
    </div>
  );
};

export default Footer;
