import { privateAxios } from 'utils/axios';
import { useMutation, useQueryClient } from 'react-query';
import { useNotificationStore } from 'contexts/NotificationStoreContext';
import getNotificationMessage from 'features/notification-store/utils/getNotificationMessage';
import { useNavigate } from 'react-router-dom';

const destroySession = async () => {
  const { data } = await privateAxios.post('/session');

  return data;
};

const useLogout = (setState, options) => {
  const { addNotification } = useNotificationStore();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  return useMutation(destroySession, {
    onSuccess: ({ message }) => {
      setState({ user: null, error: null });
      addNotification({
        isError: false,
        message: getNotificationMessage({ message }),
      });
      queryClient.clear();
      navigate('/', { replace: true });
    },
    onError: ({ response: { data: error } }) => {
      addNotification({
        isError: true,
        message: getNotificationMessage({ error }),
      });
    },
    ...options,
  });
};

export default useLogout;
