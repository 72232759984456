const getErrorMessage = (error) => {
  switch (error.type) {
    case 'MissingFieldsError':
      return 'Les champs requis ne sont pas renseignés';

    case 'AuthenticationError':
      if (error.message === 'invalid username or password')
        return 'Identifiant ou mot de passe incorrect';
      if (error.message === 'this account has not been activated')
        return "Ce compte n'a pas été activé. Veuillez consulter vos e-mails";
      if (error.message === 'invalid password')
        return 'Le mot de passe saisi est incorrect';
      return error.message;
    case 'UniqueViolation':
      return 'Cet email est déjà utilisé';
    case 'NotFoundError':
      if (error.message === 'exhibition not found')
        return "Cette exposition n'existe pas ou les inscriptions sont fermées";
      return error.message;
    case 'RegisteredToOpenExhibitionError':
      return "Vous ne pouvez pas supprimer votre compte lorsque vous êtes inscrit à une exposition en cours. Veuillez réessayer lorsque les inscriptions à l'exposition seront fermées";
    case 'InvalidFileError':
      if (error.message === 'invalid animals XML file')
        return "Ce fichier XML est invalide. Veuillez vérifier qu'il s'agit bien du fichier d'import ExpoPC relatif aux animaux";
      if (error.message === 'invalid exhibition XML file')
        return "Ce fichier XML est invalide. Veuillez vérifier qu'il s'agit bien du fichier d'import ExpoPC relatif à l'exposition";
      return error.message;
    case 'EmailSendingError':
      return "Une erreur est survenue lors de l'envoi de l'e-mail d'activation. Veuillez vérifier que l'adresse e-mail saisie est valide, puis réessayez";
    default:
      return error.message;
  }
};

const getNotificationMessage = ({ message, error }) => {
  if (error) return getErrorMessage(error);
  switch (message) {
    case 'authentication successful':
      return 'Vous êtes maintenant connecté';
    case 'successfully logged out':
      return 'Vous êtes maintenant déconnecté';
    case 'email sent if address is valid':
      return "Si l'adresse saisie est correcte, un e-mail vous a été envoyé";
    case 'account successfully created':
      return "Votre compte a bien été crée, un email d'activation vous a été envoyé";
    case 'account activated successfully':
      return 'Votre compte a bien été activé. Vous pouvez maintenant vous connecter';
    case 'password updated successfully':
      return 'Votre mot de passe a bien été mis à jour';
    case 'user details updated successfully':
      return 'Votre profil a bien été mis à jour';
    case 'account deleted successfully':
      return 'Votre compte a bien été supprimé';
    case 'exhibition created successfully':
      return 'Une nouvelle exposition a bien été créée';
    case 'animals updated successfully':
      return 'La base de donnée des animaux a bien été mise à jour';
    case 'exhibition closed successfully':
      return "Les inscriptions à l'exposition sont maintenant closes";
    default:
      return message;
  }
};

export default getNotificationMessage;
