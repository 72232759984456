import React, { useReducer, useContext, createContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { nanoid } from 'nanoid';

const NotificationStoreContext = createContext();

const { Provider } = NotificationStoreContext;

const ACTIONS = {
  ADD: 'add',
  REMOVE: 'remove',
  TRIM: 'trim',
};

const notificationStoreReducer = (
  { lastAction, notifications },
  { type, payload },
) => {
  switch (type) {
    case ACTIONS.ADD:
      return {
        lastAction: ACTIONS.ADD,
        notifications: [...notifications, payload],
      };
    case ACTIONS.REMOVE:
      return {
        lastAction: ACTIONS.REMOVE,
        notifications: notifications.filter(({ id }) => id !== payload.id),
      };
    case ACTIONS.TRIM:
      return {
        lastAction: ACTIONS.TRIM,
        notifications: notifications.slice(1),
      };
    default:
      return { lastAction, notifications };
  }
};

const NotificationStoreProvider = ({ children }) => {
  const [notificationStoreState, notificationStoreDispatch] = useReducer(
    notificationStoreReducer,
    { lastAction: null, notifications: [] },
  );

  useEffect(() => {
    if (notificationStoreState.notifications.length > 3) {
      notificationStoreDispatch({ type: ACTIONS.TRIM });
    }
  }, [notificationStoreState]);

  const addNotification = ({ isError, message }) => {
    const id = nanoid();
    const newNotification = { id, isError, message };
    notificationStoreDispatch({ type: ACTIONS.ADD, payload: newNotification });
  };

  const removeNotification = (notificationToRemove) => {
    notificationStoreDispatch({
      type: ACTIONS.REMOVE,
      payload: notificationToRemove,
    });
  };

  return (
    <Provider
      value={{
        notificationStoreState,
        addNotification,
        removeNotification,
      }}
    >
      {children}
    </Provider>
  );
};

const useNotificationStore = () => useContext(NotificationStoreContext);

NotificationStoreProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export { useNotificationStore, NotificationStoreProvider };
