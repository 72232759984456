import React, { useContext, createContext, useState } from 'react';
import PropTypes from 'prop-types';

const ClientSecretContext = createContext();

const { Provider } = ClientSecretContext;

const ClientSecretProvider = ({ children }) => {
  const [clientSecret, setClientSecret] = useState(null);

  return (
    <Provider value={{ clientSecret, setClientSecret }}>{children}</Provider>
  );
};

const useClientSecret = () => useContext(ClientSecretContext);

ClientSecretProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export { useClientSecret, ClientSecretProvider };
