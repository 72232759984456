import React, { useContext, createContext } from 'react';
import PropTypes from 'prop-types';
import { loadStripe } from '@stripe/stripe-js';

const StripeContext = createContext();

const { Provider } = StripeContext;

const StripeProvider = ({ children }) => {
  const stripe = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

  return <Provider value={{ stripe }}>{children}</Provider>;
};

const useStripe = () => useContext(StripeContext);

StripeProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export { useStripe, StripeProvider };
