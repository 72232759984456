import React from 'react';
import ReactDOM from 'react-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { BrowserRouter } from 'react-router-dom';
import './index.css';
import { NotificationStoreProvider } from 'contexts/NotificationStoreContext';
import { AuthProvider } from 'contexts/AuthContext';
import { StripeProvider } from 'contexts/StripeContext';
import { ClientSecretProvider } from 'contexts/ClientSecretContext';
import App from './components/App';

const queryClient = new QueryClient();

ReactDOM.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <BrowserRouter>
        <NotificationStoreProvider>
          <AuthProvider>
            <StripeProvider>
              <ClientSecretProvider>
                <App />
                <ReactQueryDevtools initialIsOpen={false} />
              </ClientSecretProvider>
            </StripeProvider>
          </AuthProvider>
        </NotificationStoreProvider>
      </BrowserRouter>
    </QueryClientProvider>
  </React.StrictMode>,
  document.getElementById('root'),
);
