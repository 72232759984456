import { privateAxios } from 'utils/axios';
import { useQuery } from 'react-query';

const loadSession = async () => {
  const { data } = await privateAxios.get('/session');

  return data;
};

const useSession = (setAuthState, setWaitingForSessionData, options) =>
  useQuery('session', loadSession, {
    onSettled: () => setWaitingForSessionData(false),
    onSuccess: ({ user }) => setAuthState({ user, error: null }),
    onError: ({ response: { data: error } }) =>
      setAuthState({ user: null, error }),
    ...options,
  });

export default useSession;
