import axios from 'axios';

const { REACT_APP_API_URL } = process.env;

const publicAxios = axios.create({
  baseURL: REACT_APP_API_URL,
});

const privateAxios = axios.create({
  withCredentials: true,
  baseURL: REACT_APP_API_URL,
});

export { publicAxios, privateAxios };
