import React, { Suspense } from 'react';
import { useAuth } from 'contexts/AuthContext';
import { Container } from 'react-bootstrap';
import FullPageSpinner from './FullPageSpinner';
import Footer from './Footer';

const UnauthenticatedApp = React.lazy(() => import('./UnauthenticatedApp'));
const UserAuthenticatedApp = React.lazy(() => import('./UserAuthenticatedApp'));
const AdminAuthenticatedApp = React.lazy(() =>
  import('./AdminAuthenticatedApp'),
);

const App = () => {
  const {
    authState: { user },
    isAuthenticated,
  } = useAuth();

  const getComponent = () => {
    if (!isAuthenticated) return <UnauthenticatedApp />;
    if (user.isAdmin === true) return <AdminAuthenticatedApp />;
    return <UserAuthenticatedApp />;
  };

  return (
    <Suspense fallback={<FullPageSpinner />}>
      <Container className="mt-3 mb-3">{getComponent()}</Container>
      <Footer />
    </Suspense>
  );
};

export default App;
